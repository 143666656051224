@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');

body {
    background: #F8F8F8;
    font-family: "Roboto", sans-serif;
    letter-spacing: 0.4px;
}

::-webkit-file-upload-button {
    display: none;
}

::file-selector-button {
    display: none;
}

::-webkit-scrollbar {
    width: 5px;
    display: none;
}

.bg-gradient {
    background: linear-gradient(110.39deg, rgba(255, 255, 255, 0.4) 5.38%, rgba(255, 255, 255, 0.1) 93.44%);
    -webkit-backdrop-filter: blur(10px);
    backdrop-filter: blur(10px);
}

/* 
::-webkit-scrollbar-thumb {
    background-color: grey;
}

::-webkit-scrollbar-track {
    background-color: transparent;
} */